/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    managerialSkills: ["Leadership", "Public Speaking", "Strategising", "Operations", "Planning", "Team Motivation", "Prototyping", "Research", "Wireframing", "Recruiting", "Finance Management"],
    technicalSkills: ["Android", "JavaScript", "Material UI", "Kotlin", "Java", "Angular", "Rxjava", "Git & GitHub", "Heroku", "Azure", "GCP", "AWS", "Node.js"],
    marketingSkills: ["On-Page SEO", "Off-Page SEO", "Technical SEO", "SEM", "Social Media Marketing", "Analytics"],
    education: {
        ug: {
            name: "Indian Institute Of Technology, Roorkee",
            type: "University",
            course: "Bachelors in Technology",
            duration: "2014-2018"
        },
        highSchool: {
            name: "Holy Heart Day Boarding Senior Secondary Public School",
            type: "High School",
            course: "PCM + Music",
            duration: "2004-2013"
        },
        juniorSchool: {
            name: "Holy Heart Day Boarding Senior Secondary Public School",
            type: "Primary School",
            course: "General",
            duration: "2000-2004"
        }
    },
    work: [
        {
            company: "Powerplay",
            link: "https://www.getpowerplay.in/",
            role: "Senior Software Engineer",
            description: ["Working as an all-rounded software engineer for Powerplay, I was able to balance both corporate and startup work-styles as Trademarkia is an established company running from more than 12 years whereas Nearcast is a startup we had to bring up from scratch.", "I helped them create their own in-house engineering teams as the tech team was outsourced before. This included understanding business logic, the tech stack used and hire a team to maintain legacy code and relaying the same to new developers who parallelly revamped the applications into modern tech stacks.", "I also helped set up the engineering office in Chennai and handled the finances and operations for the Indian entity.", "Setting deadlines, tracking progress, motivating developers & designers, striving towards a product-market fit and coordinating with VCs and Advisors is something I did on a daily basis.", "Whenever there was a deadline, I did not hesitate to contribute to the Flutter app and React web teams. I ensured code quality was upto production standards."],
            duration: "Jun 15, 2021 - Present",
            feedback: "",
        },
        {
            company: "Zupee",
            link: "https://www.zupee.in",
            role: "Android Developer",
            description: ["I started my journey with Agora by winning a hackathon sponsored by Agora. I started exploring the Agora SDKs and joined the Superstars program. I continued as a member of the program for over a year and mastered the Web SDKs for both RTC and RTM.", "I was then promoted to the role of a Developer Evangelist Intern in January 2021 under the DevRel team.", "In this journey, I was able to help thousands of developers by making Open-Source projects demo-ing how the Web SDK works and how the most daunting tasks could be solved with few lines of code.", "Along with a colleague, I conducted workshops teaching Web Development in universities like Columbia, Penn State, NYU, Harvard, UCSC and CalTech, helping participants learn how they could make a functional video calling websites.", "I managed the web support channels on Slack as well as Stack Overflow to ensure developers could utilise the fullest potential of the Agora SDKs.", "Lastly, I also wrote technical blogs to serve as guides for priority features and examples which would be helpful to developers."],
            duration: "Aug 2020 - Jun 2021",
            feedback: "",
        },
        {
            company: "Bharatlend",
            link: "https://www.bharatlend.com/",
            role: "CEO & Co-founder",
            description: ["I recruited over 10 junior interns and led an entire engineering team working on multiple projects as both a Technical Manager as well as Software Developer.", "I worked on the branding, designing and SEO of all websites developed by Josh Talks. The SEO gave results and within 2 weeks- a website I developed and deployed from scratch was now a top search result on Google.", "I maintained legacy projects written in Django, NodeJS, Bootstrap and MatieralizeCSS.", "I helped develop over 5 large-scale projects within a year, increasing sales and profits by thousands of INR.", "Delegating work, coding, coordinating with the product team, and recruitments were my daily routine."],
            duration: "Aug 2019 - Aug 2021",
            feedback: "../Assets/JoshTalks1Year.pdf",
        },
        {
            company: "Schlumberger India Pvt. Ltd.",
            link: "https://slb.com/",
            role: "Software Developer",
            description: ["Despite being a short internship, I was able to give immediate results in this role. I was responsible to improve the company's internet presence.", "I achieved this by revamping the old website with a new design and better on-site SEO. This led to the website being ranked up on a simple Google search within a week.", "I made the landing pages for two ventures under the company- Flipped.ai and bublr.io which scaled up after I left.", "I also linked the frontend I developed with a Django backend through server-side rendering for a top-priority project."],
            duration: "Jul 2018-Aug 2019",
            feedback: "../Assets/GAIUS_Networks.pdf",
        },
        {
            company: "Wealthy.in",
            role: "Android Developer",
            link: "http://www.wealthy.in/",
            description: ["As the only Frontend Developer working on the projects assigned to me, I made two portals as a part of my internship- Fame Recruit and Master Connects.", "I coordinated with the backend, deployment and management teams to maintain sync between all members.", "I also created multiple sub-portals for different stakeholders for the same product so that we could make the most out of each portal, exponentially increasing the product's use-case."],
            duration: "Jun 2017 - Aug 2017",
            feedback: "../Assets/FameTech.pdf",
        }
    ],
    accomplishments: [
    //     {
    //     award: "Profitech 2021",
    //     description: "Won the First Place in the Technology Track out of 240 registered global users.",
    //     by: "Profitech",
    //     when: "Aug 2021",
    // }, {
    //     award: "DEVSPACE 2021",
    //     description: "Won the Second Place in the Industrial Collaborations by Dyte track out of 1,414 registered users.",
    //     by: "Computer Society of India- VIT University",
    //     when: "Mar 2021",
    // }, {
    //     award: "WomenTechies 2021",
    //     description: "Won the Second Runner Up award in this women-centric hackathon out of around 380 registered users.",
    //     by: "DSC VIT",
    //     when: "Mar 2021",
    // }, {
    //     award: "VIT Hack 2020",
    //     description: "Won the first place in the Gravitas AI Case partner problem statement as well as the AWS Educate open challenge out of over 1,200 registered participants.",
    //     by: "Indian Institute Of Technology, Roorkee",
    //     when: "Oct 2020",
    // }, {
    //     award: "Achiever & Special Achiever Award 2020",
    //     description: "Awarded the Achiever Award & Special Achiever Award for exemplary performance at various National & International events and in recognition of my academic performance.",
    //     by: "Indian Institute Of Technology, Roorkee",
    //     when: "Sep 2020",
    // }, {
    //     award: "RTE Hack 2020",
    //     description: "Won the Audience’s Favourite Award by being the top voted and most liked project of the hackathon.",
    //     by: "Agora.io",
    //     when: "Sep 2020",
    // }, {
    //     award: "HopHacks Fall 2019",
    //     description: "Won the best Healthcare Hack award powered by Siemens Healthineers out of 44 submitted projects.",
    //     by: "Johns Hopkins University",
    //     when: "Sep 2019",
    // }, {
    //     award: "PennApps 2019",
    //     description: "Qualified for the top 30 teams out of 242 worthy teams.",
    //     by: "University of Pennsylvania",
    //     when: "Sep 2019",
    // }, {
    //     award: "IBM Challenge",
    //     description: "Won the IBM Challenge in AngelHack Bangalore out of 46 submitted projects.",
    //     by: "AngelHack India",
    //     when: "Jun 2019",
    // }, {
    //     award: "VinHack",
    //     description: "Won third place in VinHack, hosted by VinnovateIT out of 213 registered participants.",
    //     by: "VinnovateIT",
    //     when: "Mar 2019",
    // },
     {
        award: "code.fun.do",
        description: "Won 2nd Place in code.fun.do hosted by Microsoft out 200+ registered participants.",
        by: "Microsoft",
        when: "May 2017",
    }, {
        award: "Aarambh Startup Boot Camp 2016",
        description: "Top 10 finalist out of 85 teams in IITR Heritage Fund out of 100+ registered teams.",
        by: "IIT Roorkee",
        when: "Oct 2016",
    },],
};