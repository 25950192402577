import { Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "./AboutStyles.jsx";
import { useEffect } from "react";

function AboutSection() {
    const classes = styles();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        let url = window.location.href.split("/");
        let target = url[url.length - 1].toLowerCase();
        let element = document.getElementById(target);
        element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);
    return (
        <>
            {/* About Section */}
            <Container maxWidth="lg" id="about-section">
                <Grid
                    container
                    className={classes.root}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography variant="h3" className={classes.title}>
                            About <span className={classes.textColored}>Me</span>
                        </Typography>
                        <Typography variant="h6" className={classes.subtitle}>
                            Originally from a small city named Fazilka in Punjab state in India, I currently work at <a href="https://www.getpowerplay.in/" target="_blank" rel="noreferrer" className={classes.hiddenLinks}>Powerplay</a> as a Senior Software Engineer, Android.
                            I take care of the Android and Product Engineering for whole mobile app along with other 6 developers. I pursued my Bachelors in Technology degree in Metallurgical and Materials Engineering from <a href="https://www.iitr.ac.in" target="_blank" rel="noreferrer" className={classes.hiddenLinks}>Indian Institute of Technology, Roorkee</a>.
                        </Typography>
                        <Typography variant="h6" className={classes.subtitle}>
                            I am a Software Engineer by profession and an Entreprenuer by heart. I had initially begun my career in the field of <b>Android Development</b> because it felt amazing solving user's problem with what you yourself have created. I loved figuring out problems, develop app and acquiring users. I then tried to pursue the role of a <b>Entrepreneur</b>. I explored multiple technical domains till I found the right fit for me- <b>Mobile App Development</b>. Along with a few friends, I co-founded a few <b>startups</b>. The startups did not work out, but it triggered the <b>business mindset</b> in me. I have now found my passion and I work on a <b>well-rounded Product Engineering</b> role. Being from a technical background helps me guide developers and designers to set goals which can meet a realistic deadline, without compromising on quality.
                        </Typography>
                        <Typography variant="h6" className={classes.subtitle}>
                            In my free time, I binge on Netflix and YouTube, listen to songs, read non fiction, go for long drives, and try to learn new technologies (currently blockchain and web3).
                        </Typography>
                    </Grid>
                </Grid>
                {!small && (<span className="scroll-btn">
                    <a href="#skills-section" onClick={e => {
                        let whereTo = document.getElementById("skills-section");
                        e.preventDefault();
                        whereTo && whereTo.scrollIntoView({ behavior: "smooth", block: "start" });
                    }} style={{
                        display: "flex", justifyContent: "center",
                    }}
                    >
                        <span className="mouse">
                            <span>
                            </span>
                        </span>
                    </a>
                </span>
                )}
            </Container>
        </>
    );
}

export default AboutSection;

